/*===================
    Header Page
=====================
1. main_header
2. responsive css

================*/

/*======== main_header start ========*/

.main_header .offcanvas-header .btn-close {
    position: absolute;
    right: 18px;
}
.main_header .nav-link {
    color: var(--white-color );
}
.main_header .logo img{
    height: 3.75rem;
}
.main_header .icon_box{
    color: #fff;
}
.main_header .icon_box svg{
    transition : all .3s ease;
}
.main_header .icon_box svg:hover{
    color:#d5a770 ;
}
.main_header .icon_box a:hover{
    color:#d5a770 ;
}
.main_header .translate_bottom{
    transform: translate(-34%,0%)!important;
}
.main_header .searchbox{
    background-color: #343434;
    border: 0;
    border-radius: 5rem;
    color: #fff;
    font-weight: 400;
    height: 3rem;
}
.main_header .searchbox .end_button{
    right: 20px;
}
.main_header .searchbox .input-group-text{
    background-color: transparent;
    border: 0;
    color: white;
    padding-top: 0px;
    padding-bottom: 0px;
}
.main_header .searchbox .form-control{
    background-color: transparent;
    border: 0;
    color: white;
    padding-top: 0px;
    padding-bottom: 0px;
    box-shadow: none;
}

/*======== main_header end ========*/


/*======== responsive start ========*/
@media (min-width: 1350px){
    .main_header .logo img{
        width: 15rem;
    }
}
/*======== responsive end ========*/


.offcanvas {
    background-color: #343434 !important;
    color: white !important;
}
.offcanvas .icon_box {
    justify-content: center;
}