.under_maintenance {
  background-color: #252525;
}

.coming_soon {
  font-size: 39px;
  font-weight: 600;
  color: #fff;
  letter-spacing: 1.5px;
  /* margin-top: 37vh; */
  margin-top: 10vh;
}

.image_icon img {
  margin-top: 10vh;
  width: 120px;
  transform: scale(1.5);
}
.password_field .form-control {
  /* border: 5px solid var(--t-sonic_silver); */
  border-radius: 5px;
  height: 38px;
  background-color: transparent;
  color: white;
}

.password_field .form-control:focus {
  /* border: 5px solid var(--t-sonic_silver); */
  border: 1px solid #fff;
  background-color: transparent;
  color: white;
}

.password_field .form-control::placeholder {
  text-align: center;
}
