/*===================
    Product Page
=====================
1. product_filter
    a. filter_header
    b.filter_content
2. product_content
3. notification
4. responsive css

================*/

/*======== product_filter start ========*/

/* filter_header start */

.product .filter_header {
  border-bottom: 1px solid #e8e7e4;
  border-top: 1px solid #e8e7e4;
}

.product .filter_header .filter {
  cursor: pointer;
  border-bottom: 1px solid transparent;
  transition: 0.2s ease-in-out;
}

.product .filter_header .filter:hover {
  border-bottom: 1px solid black;
}

.product .filter_header .rotate {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transition: 0.2s ease-in-out;
}

/* filter_header end */

/* filter_content start */

.filter_wrapper {
  visibility: visible;
  z-index: 20;
  opacity: 1;
  position: absolute;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
  right: 0;
  left: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  padding: 0 24px;
  background-color: white;
}

.filter_wrapper .content {
  border-right: 2px solid #f5f5f5;
  border-left: 2px solid #f5f5f5;
}

.filter_wrapper .content .accordion-item {
  border: 0;
}

.filter_wrapper .content .accordion-body {
  background-color: #f9f9f9;
  height: 100%;
}

.filter_wrapper .content .accordion-button,
.filter_wrapper .content .accordion-button:not(.collapsed) {
  border: 0;
  box-shadow: none;
  background-color: transparent;
  color: black;
  font-weight: 600;
}

.filter_wrapper .content .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23YOUR_HEX_CODE'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

/* filter_content end */

/*======== product_filter end ========*/

/*======== product_content start ========*/

.product .product_content .single_product {
  width: 25%;
  border-right: 1px solid #e8e7e4;
  border-bottom: 1px solid #e8e7e4;
  background-color: white;
  transition: 0.2s ease-in-out;
}

.product_content .single_product:hover {
  background-color: #e8e7e4;
}

.product_content .single_product img {
  width: 100%;
  /* max-height: 550px; */
  max-height: 475px;
  object-fit: cover;
}

.product_content .single_product .right .icon {
  background-color: rgba(0, 0, 0, 0.1);
  color: black;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.15s ease-in-out;
}

.product_content .single_product .right .icon:hover {
  background-color: #d5a770;
  color: #fff;
}

/*======== product_content end ========*/

/*======== notification start ========*/
.product .toast-container {
  position: fixed !important;
}
/*======== notification end ========*/

/*======== responsive start ========*/

@media screen and (max-width: 575px) {
  .product_content .single_product {
    width: 100%;
  }

  .filter_wrapper {
    grid-template-columns: 1fr;
    max-width: 300px;
    max-height: 80vh;
    overflow-y: auto;
    border-radius: 0 0 5px 0;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .product_content .single_product {
    width: 50%;
  }

  .filter_wrapper {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .filter_wrapper {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

/*======== responsive end ========*/
