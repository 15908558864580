@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&family=Open+Sans:wght@300;400;500;600;700;800&display=swap");

:root {
  --success-color: #2ecc71;
  --error-color: #e74c3c;
}

* {
  box-sizing: border-box;
}

.form_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  margin: 0;
}

.form_wrapper .container {
  border-radius: 5px;
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3); */
  width: 500px !important;
  width: auto;
}
.form_wrapper .eye_icon {
  cursor: pointer;
  position: absolute;
  right: 4%;
  bottom: 35%;
}

h1 {
  font-family: "Open Sans", sans-serif;
  text-align: center;
  margin: 0 0 20px;
  font-weight: 800;
}

.form {
  padding: 10px 40px 40px;
}

.form-control:focus {
  box-shadow: none !important;
}

.form_group {
  margin-bottom: 10px;
  padding-bottom: 20px;
  position: relative;
}

.form_group label {
  color: rgb(0, 0, 0);
  display: block;
  margin-bottom: 5px;
}

.form_group input {
  border: 2px solid #f0f0f0;
  border-radius: 4px;
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 14px;
}

.form_group input:focus {
  outline: none;
  border-color: rgb(0, 0, 0);
}

.form_group.success input {
  border-color: var(--success-color);
}

.form_group.error input {
  border-color: var(--error-color);
}

.form_group .small_msg {
  color: var(--error-color);
  position: absolute;
  bottom: 0;
  left: 0;
}

.form_group.error .small_msg {
  visibility: visible;
}

@media screen and (max-width: 450px) {
  .form_wrapper .container {
    min-width: 320px !important;
  }
}
