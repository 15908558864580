/*===================
    relatedProduct CSS 
=====================
1. img
2. icon
3. notification
================*/

/*======== relatedProduct start ========*/

.relatedProduct .figure img {
    height: 350px;
    object-fit: cover;
}
.relatedProduct .collection_content .right .icon {
    background-color: rgba(0, 0, 0, .1);
    color: black;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color .15s ease-in-out;
}

.relatedProduct .collection_content .right .icon:hover {
    background-color: #d5a770;
    color: #fff;
}

.relatedProduct .toast-container {
    position: fixed !important;
}

/*======== relatedProduct end ========*/
