/*===================
    Global Css  
=====================
1. font import
2. reset css
3. variable set up
4. color
5. background color
6. font size
7. font weight
8. padding
9. common css
9. media queries
================*/


/*======== font import from global ========*/
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap');

/*======== reset css ========*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
}

/*======== variable set up ========*/

:root {
  --dark: #000;
  --dark-color: #212529;
  --light-dark-color: #202020;
  --light-dark-color2: #201f1e;
  --light-grey: #605f5d;
  --light-grey0: #9d9d9d;
  --light-grey2: #eeeceb;
  --white-color: #ffffff;
  --gold-color: #d5a770;
  --olive-color: #f6f3ec;
  --light-gold-color: #f0e7d8;
  --red-color: #d0112b;
  --light-green: #73C95D;

  --home-color1: #fafafa;
  --home-color2: #fcfaf5;


}

/*======== color ========*/

.cl_white {
  color: var(--white-color);
}

.cl_dark {
  color: var(--dark-color);
}

.cl_dark:hover {
  color: var(--dark-color);
}

.cl_gold {
  color: var(--gold-color);
}

.cl_lightDark2 {
  color: var(--light-dark-color2);
}

.cl_lightGrey {
  color: var(--light-grey);
}
.cl_lightGreen{
  color: var(--light-green);
}

.cl_lightGrey0 {
  color: var(--light-grey0);
}

/*======== background color ========*/
.bg_Dark {
  background-color: var(--dark);
}

.bg_white {
  background-color: var(--white-color);
}

.bg_lightDark {
  background-color: var(--light-dark-color);
}

.bg_lightDark {
  background-color: var(--light-dark-color);
}

.bg_lightDark2 {
  background-color: var(--light-dark-color2);
}

.bg_lightGold {
  background-color: var(--light-gold-color);
}
.bg_red {
  background-color: var(--red-color);
}
.bg_olive {
  background-color: var(--olive-color);
}

.bg_lightGrey2 {
  background-color: var(--light-grey2);
}

.bg_home1 {
  background-color: var(--home-color1);
}

.bg_home2 {
  background-color: var(--home-color2);
}

/*======== font size ========*/

.font_size_12 {
  font-size: 0.75rem;
}

.font_size_14 {
  font-size: 0.875rem;
}

.font_size_16 {
  font-size: 1rem;
}

.font_size_18 {
  font-size: 1.125rem;
}

.font_size_21 {
  font-size: 1.3125rem;
}

.font_size_25 {
  font-size: 1.5625rem;
}

.font_size_28 {
  font-size: 1.75rem;
}

.font_size_32 {
  font-size: 2rem;
}

.font_size_40 {
  font-size: 2.5rem;
}

.font_size_50 {
  font-size: 3.125rem;
}

.font_size_90 {
  font-size: 5.625rem;
}



/*======== font weight ========*/
.fw_500 {
  font-weight: 500;
}
.fw_600 {
  font-weight: 600;
}

.fw_700 {
  font-weight: 700;
}

/*======== padding ========*/

.sesction_pt {
  padding-top: 4rem;
}

.sesction_pb {
  padding-bottom: 4rem;
}

/*======== common css ========*/



/* table */

table td {
  vertical-align: middle;
  /* min-width: 170px; */
}
.table_image {
width: 200px;
}

/* letter space */
.ls_002 {
  letter-spacing: 0.02rem;
}

.ls__1 {
  letter-spacing: -1px;
}

/* text underline effect */
.underline_offset_6 {
  text-underline-offset: 0.4rem;
}

/* text decoration */
.underline_none {
  text-decoration: none;
}

/* cursor style */
.pointer {
  cursor: pointer;
}

/* transition */
.transition_15_eio {
  transition: .15s ease-in-out;
}

/* object fit */
.object_fit {
  object-fit: cover
}

/* list style */
.l_s_none {
  list-style: none;
}

/* width */
.width_90 {
  width: 90% !important;
}

/* slick slider css  */

.slick-list {
  margin: 0 -10px;
}

.slick-slide>div {
  padding: 0 10px;
}

.slick-next,
.slick-prev {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}


/*  ================== media queries =================== */

@media screen and (max-width: 767px) {
  .custom_container {
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
  .sesction_pt {
    padding-top: 3rem;
  }
  
  .sesction_pb {
    padding-bottom: 3rem;
  }

  /* font size */
  .font_size_16 {
    font-size: 0.875rem;
  }

  .font_size_21 {
    font-size: 1.25rem;
  }

  .font_size_28 {
    font-size: 1.563rem;
  }
  .font_size_32 {
    font-size: 1.563rem;
  }

  .font_size_50 {
    font-size: 1.938rem;
  }
  .font_size_40 {
    font-size: 1.563rem;
  }

  .font_size_90 {
    font-size: 3rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .custom_container {
    max-width: 730px;
    margin: 0 auto;
    padding: 0 15px;
  }

  /* font size */
  .font_size_16 {
    font-size: 0.875rem;
  }

  .font_size_21 {
    font-size: 1.25rem;
  }

  .font_size_28 {
    font-size: 1.563rem;
  }
  .font_size_32 {
    font-size: 1.563rem;
  }
  .font_size_40 {
    font-size: 1.875rem;
  }

  .font_size_50 {
    font-size: 2.438rem;
  }

  .font_size_90 {
    font-size: 3.813rem;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .custom_container {
    max-width: 960px;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (min-width: 1200px) and (max-width: 1439px) {
  .custom_container {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (min-width: 1440px) and (max-width: 1919px) {
  .custom_container {
    max-width: 1330px;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media screen and (min-width: 1920px) {
  .custom_container {
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 15px;
  }
}


