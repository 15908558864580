/*======== Sign in / Sign Up / Forget Password CSS start ========*/

.logIn {
    min-height: 100vh;
}

.logIn .logInForm{
    width: 400px;
}

.logIn .logInForm .form .form-control:focus {
    box-shadow: none ;
}

.logIn .logInForm .form .form_group label {
    display: block;
    margin-bottom: 5px;
}

.logIn .logInForm .form .form_group input {
    border: 2px solid #f0f0f0;
    border-radius: 4px;
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 14px;
}

.logIn .logInForm .form .form_group input:focus {
    outline: none;
    border-color: rgb(0, 0, 0);
}
.logIn .logInForm .form .form_group .eye_icon {
    bottom: 40%;
    cursor: pointer;
    position: absolute;
    right: 4%;
}

.logIn .logInForm .btn, .logIn .logInForm .btn:active {
    background-color: #000;
    border: none;
    border-radius: 0;
    padding-top:10px ;
    padding-bottom:10px ;
}


.logIn .link_tag a{
    font-weight: 600;
    text-decoration: none;
    font-size: 14px;
    transition: .15s ease-in-out;
}
.logIn .link_tag a:hover{
    color: var(--light-dark-color2);
}

.logIn input.form-check-input:checked {
    box-shadow: none;
    background-color: rgb(0, 0, 0);
    border-color: rgb(0, 0, 0);
}
.logIn .form-check-input:focus {
    box-shadow: none;
    border-color: rgb(0, 0, 0);
}

/*======== Sign in / Sign Up / Forget Password CSS end ========*/