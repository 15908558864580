/*===================
    Footer 
=====================

1. footer_top_subscriber
2. footer_mid
3. footer_end

================*/


/*======== footer_top_subscriber start ========*/

.footer_top_subscriber {
    background-image: url("../../../Assets/footer_banner.jpg");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 4.375rem 0;
}
.footer_top_subscriber .form-control {
    border : 1px solid transparent;
    background-clip : unset;
    padding: 0.75rem 0.75rem;
}
.footer_top_subscriber .form-control:focus{
    border : 1px solid transparent;
    background-clip : unset;
    box-shadow: none;

}
.footer_top_subscriber .input-group-text {
    border : 1px solid transparent;
    background-color: var(--gold-color);
    color: var(--white-color);
    padding: 0.75rem 0.75rem;
    cursor: pointer;
}

/*======== footer_top_subscriber end ========*/


/*======== footer_end start========*/

.footer_end .footer_logo {
    width: 130px;
}
.footer_end .sub_category a{
    color: var(--light-grey0);
    text-decoration: none;
    transition: .15s ease-in-out;
    
}
.footer_end .sub_category a:hover{
    color: var(--white-color);
}
.footer_end hr {
    opacity: .2;
    border: 0;
    border-top: 1px solid #eee;
    margin-bottom: 20px;
    margin-top: 20px;
}

/*======== footer_end end ========*/