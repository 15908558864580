/* User Profile Navbar */

.user_profile_navbar {
  /* background-color: #000; */
  background-color: #1b1b1b;
  /* background-color: rgb(243, 245, 246); */
  padding: 8px 50px;
}

.navbar-nav .nav-link.active,
.navbar-nav .show > .nav-link {
  color: rgb(255, 255, 255) !important;
}

.user_profile_navbar .nav-link,
.user_profile_navbar .nav-link:hover,
.user_profile_navbar .navbar-nav .show > .nav-link,
.user_profile_navbar .dropdown-toggle:after {
  color: #fff;
}

.user_profile_navbar .nav-link:nth-child(2) span {
  padding-right: 15px;
  border-right: 1px solid #fff;
}

.user_profile_navbar .logo_img_wrapper {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #444;
  overflow: hidden;
}

.user_profile_navbar .dropdown-menu[data-bs-popper] {
  left: -80px;
}

/* User Profile Sidebar */

.user_profile_dashboard .user-profile-sidebar {
  width: 400px;
  background: #1b1b1b;
  transition: all 0.3s ease;
  z-index: 4;
  /* height: calc(100vh - 72px); */
  min-height: 100vh;
}

.user-profile-sidebar .logo_img_wrapper {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #444;
  overflow: hidden;
}

.user-profile-sidebar li {
  list-style: none;
}

/* .user-profile-sidebar .icon {
  min-width: 60px;
  color: #ababab;
  font-size: 20px;
} */
.user-profile-sidebar .user_profile_icon {
  min-width: 60px;
  color: #ababab;
  font-size: 20px;
}

.user-profile-sidebar .user_profile_text {
  font-size: 18px;
  white-space: nowrap;
  opacity: 1;
  color: #ababab;
  transition: all 0.3s ease;
}

.user-profile-sidebar.close .user_profile_text {
  opacity: 0;
}

.user-profile-sidebar.close .projects_nav_link .user_profile_text,
.user-profile-sidebar.close .projects_nav_link .icons,
.user-profile-sidebar.close .projects_nav_link_images {
  opacity: 0;
  display: none;
}

.user-profile-sidebar header {
  position: relative;
}

.user-profile-sidebar header .toggle {
  position: absolute;
  top: 60%;
  right: -55px;
  transform: translateY(-50%) rotate(180deg);
  height: 24px;
  width: 24px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 35px;
  cursor: pointer;
  transition: all 0.3s ease;
}

body.dark .user-profile-sidebar header .toggle {
  color: var(--text-color);
}

.user-profile-sidebar.close .toggle {
  transform: translateY(-50%) rotate(0deg);
}

.user-profile-sidebar a li {
  list-style: none;
  height: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  text-decoration: none;
  transition: all 0.3s ease;
  padding: 14px 16px;
  border-top: 1px solid #252525;
}

.user-profile-sidebar .menu-links a {
  text-decoration: none;
}

.user-profile-sidebar .user_logout .nav-link {
  padding: 14px 1rem;
}

.user-profile-sidebar .nav-link:hover {
  background-color: #252525;
}
.user-profile-sidebar .active .nav-link {
  /* border-left: 4px solid #1ed760; */
  border-left: 4px solid #0289ff;
}

.user-profile-sidebar .nav-link:hover svg,
.user-profile-sidebar .active .nav-link svg,
.user-profile-sidebar .nav-link:hover .nav-text,
.user-profile-sidebar .active .nav-link .nav-text {
  color: rgb(255, 255, 255);
  font-weight: 400;
}

.user-profile-sidebar .projects_link:hover,
.user-profile-sidebar .nav-link:hover svg {
  background-color: unset;
}

.user-profile-sidebar .menu-bar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
}

.user-profile-sidebar .menu-bar .activeLink {
  background-color: var(--primary-color);
  border-radius: 6px;
}

.user-profile-sidebar .menu-bar .activeLink svg,
.user-profile-sidebar .menu-bar .activeLink .nav-text {
  color: #4936b6;
}

/* ===== Sidebar ===== */
.user_profile_dashboard .user-profile-sidebar.close {
  width: 88px;
}

.user_profile_dashboard .user-profile-rightbar {
  background-color: #fff;
}

@media screen and (max-width: 576px) {
  .user_profile_dashboard .user-profile-sidebar .text {
    opacity: 0;
  }
  .user_profile_dashboard .user-profile-sidebar {
    width: 88px;
  }
  .user_profile_dashboard .user-profile-sidebar.close {
    width: 0;
    transform: translateX(-100%);
  }
  .user_profile_dashboard .user-profile-sidebar header .toggle {
    top: -8px;
    right: -30px;
    transition: all 0.2s ease-in-out;
  }
  .user_profile_dashboard .user-profile-sidebar.close .icon {
    display: none;
  }
  .user_profile_dashboard .user-profile-sidebar .text {
    display: none;
  }

  .user_profile_dashboard .user-profile-sidebar.close .nav-link,
  .user_profile_dashboard .user-profile-sidebar.close a.active .nav-link {
    padding: 14px 0;
  }

  .user_profile_dashboard .topbar .search_box {
    width: 80%;
    margin: auto;
  }
}

.menu-bar::-webkit-scrollbar {
  display: none;
}

.user-profile-rightbar {
  /* height: calc(100vh - 72px); */
  width: 100%;
  background-color: white;
  transition: all 0.3s ease;
  z-index: 3;
  overflow: auto;
}

.eye_icon {
  cursor: pointer;
  position: absolute;
  right: 15px;
  bottom: 27px;
}

.form_control_eye_icon {
  cursor: pointer;
  position: absolute;
  right: 15px;
  bottom: 10px;
}

/* ===================== All Page [ outlet ] code ===================== */

/* ======================> Account overview <============================*/

.user_dashboard_content .form_group {
  border-bottom: 1px solid #e1e1e1;
  padding: 10px 0;
}

.user_dashboard_content .form_group label {
  color: #909090;
}

.user_dashboard_content .form_group .form-control,
.user_dashboard_content .form_group .form-control:focus,
.user_dashboard_content .form_group .form-control:active {
  border: none;
  box-shadow: none;
}

.user_dashboard_content label {
  color: #000;
  font-size: 14px;
  font-weight: 700;
}

.user_dashboard_content .form-control {
  border-color: #888;
  border-radius: 4px;
  line-height: 33px;
}

.user_dashboard_content .form-control:focus,
.user_dashboard_content .form-control:active {
  box-shadow: none;
}

.user_dashboard_content .edit_profile_btn button,
.user_dashboard_content .signout_everywhere_btn button {
  background-color: transparent;
  padding: 10px 30px;
  border: 1px solid #626262;
  border-radius: 40px;
  font-weight: 700;
  transition: all 0.3s ease-in-out;
}

.user_dashboard_content .edit_profile_btn button a {
  text-decoration: none;
  color: black;
}

.user_dashboard_content .edit_profile_btn button:hover,
.user_dashboard_content .signout_everywhere_btn button:hover {
  background-color: #e8e8e8;
}

.user_dashboard_content .note {
  padding: 15px;
  background-color: #f8f8f8;
  border: 1px solid #e1e1e1;
}

/* ======================> Edit Profile <============================*/

/* .user_dashboard_content .edit_profile .save_profile_btn button:nth-child(1) {
  border: none;
  background-color: transparent;
  padding: 10px 30px;
  transition: all 0.3s ease-in-out;
  color: #5c5c5c;
  font-weight: 500;
} */

.user_dashboard_content .edit_profile .save_profile_btn button:nth-child(1) {
  /* background-color: #af7a6d; */
  background-color: #0289ff;
  color: white;
  padding: 10px 30px;
  border: none;
  border-radius: 40px;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}

.user_dashboard_content
  .edit_profile
  .save_profile_btn
  button:nth-child(1):hover {
  /* background-color: #653239; */
  background-color: #0068c1;
}

/* ======================> Password Change <============================*/

/* .user_dashboard_content .edit_password .save_profile_btn button:nth-child(1) {
  border: none;
  background-color: transparent;
  padding: 10px 30px;
  transition: all 0.3s ease-in-out;
  color: #5c5c5c;
  font-weight: 500;
} */

.user_dashboard_content .edit_password .save_profile_btn button:nth-child(1) {
  /* background-color: #1ed760; */
  background-color: #0289ff;
  color: white;
  /* color: white; */
  padding: 10px 30px;
  border: none;
  border-radius: 40px;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}

.user_dashboard_content
  .edit_password
  .save_profile_btn
  button:nth-child(1):hover {
  /* background-color: #14ce55; */
  background-color: #0068c1;
}

/* ======================> Notification settings <============================*/
.user_dashboard_content .notification_settings .form-check-input:checked {
  background-color: #169b45;
}
.user_dashboard_content .notification_settings .form-check-input:focus {
  box-shadow: none;
}

/* ============================> Receipt <==============================*/
.user_dashboard_content .receipt thead th {
  color: #8a8a8a;
  font-weight: 600;
}

.user_dashboard_content .receipt tbody td:first-child {
  font-weight: 700;
}

.user_dashboard_content .receipt tbody td:not(:first-child),
.user_dashboard_content .receipt tbody a {
  color: #8a8a8a;
  font-weight: 500;
}

/* ======================> Receipt details <============================*/

.user_dashboard_content .receipt_details .form-label {
  color: #000;
}

.user_dashboard_content .receipt_details .view_order_btn {
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 3px;
  color: #5c5c5c;
}
.user_dashboard_content h1 {
  text-align: left;
}

@media screen and (max-width: 768px) {
  .user_dashboard_content h1 {
    font-size: 25px;
    text-align: left !important;
  }

  .user_dashboard_content .edit_password .save_profile_btn button:nth-child(1) {
    padding: 10px;
  }

  .user_dashboard_content .edit_profile .save_profile_btn button:nth-child(2),
  .user_dashboard_content .edit_password .save_profile_btn button:nth-child(2) {
    padding: 10px 20px;
    font-size: 15px;
  }
}
